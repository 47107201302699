import type { ChangeEvent } from "react"
import { useEffect, useState } from "react"
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"
import { getAccount } from "@solana/spl-token"
import { useConnection, useWallet } from "@solana/wallet-adapter-react"
import { PublicKey } from "@solana/web3.js"
import { BN } from "bn.js"
import * as Yup from "yup"
import { Form, Formik } from "formik"

import { useStakeAccount } from "web3/solana/wormhole-staking/StakeAccountProvider"
import { WHTokenBalance } from "web3/solana/wormhole-staking/WHTokenBalance"
import {
  TransactionStatus,
  useMultigovStaking,
} from "web3/solana/wormhole-staking/useMultigovStaking"
import { useSolanaTransactionToast } from "web3/solana/providers/SolanaTransactionToastProvider"
import FormInput from "ui/components/form/FormInput"
import { isSolanaAddress } from "web3/helpers/address"
import UserAvatar from "common/components/UserAvatar"
import type { Account } from "query/graphql"
import { getDisplayName } from "user/helpers/user"
import { shortSolAddress } from "common/helpers/string"

const FAILED_VALIDATION_MESSAGE = "Please enter a valid Solana address"

type StakingDelegateModalProps = {
  isOpen: boolean
  onClose: () => void
  delegateeAccount?: Account
}

const StakingDelegateModal = ({
  isOpen,
  onClose,
  delegateeAccount,
}: StakingDelegateModalProps) => {
  const { showTransactionToast } = useSolanaTransactionToast()
  const { stakeAccountCustodyAddress } = useStakeAccount()
  const {
    delegate,
    getStakeMetadataAddress,
    transactionStatus,
    previousTransactionStatus,
    error,
  } = useMultigovStaking()
  const { connection } = useConnection()
  const { publicKey } = useWallet()

  const [custodyBalance, setCustodyBalance] = useState("")
  const [isDelegatingTo, setIsDelegatingTo] = useState(false)
  const [delegateeIneligible, setDelegateeIneligible] = useState(false)

  async function handleDelegate(delegatee: PublicKey | null) {
    if (!delegatee || !custodyBalance) {
      return
    }

    return delegate(delegatee)
  }

  useEffect(() => {
    if (transactionStatus === TransactionStatus.SUCCESS) {
      onClose()
    }
  }, [onClose, transactionStatus])

  function handleClose() {
    setIsDelegatingTo(false)
    setDelegateeIneligible(false)
    onClose()
  }

  async function handleSubmit(values: { delegateeSolanaAddress: string }) {
    if (!values.delegateeSolanaAddress) {
      return
    }

    const delegateePublicKey = new PublicKey(values.delegateeSolanaAddress)
    const delegateeStakeAccount = await getStakeMetadataAddress(
      delegateePublicKey,
    )

    if (!delegateeStakeAccount) {
      setDelegateeIneligible(true)

      return
    }

    return handleDelegate(delegateePublicKey)
  }

  useEffect(() => {
    async function getCustodyDetails() {
      if (!stakeAccountCustodyAddress) {
        return
      }

      try {
        const custody = await getAccount(connection, stakeAccountCustodyAddress)

        const wh = new WHTokenBalance(new BN(custody.amount.toString()))

        setCustodyBalance(wh.toString())
      } catch {
        setCustodyBalance("")
      }
    }

    getCustodyDetails()
  }, [stakeAccountCustodyAddress, connection])

  useEffect(() => {
    showTransactionToast({
      status: transactionStatus,
      previousStatus: previousTransactionStatus,
      error,
    })
  }, [
    transactionStatus,
    previousTransactionStatus,
    error,
    showTransactionToast,
  ])

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent top={{ base: "unset", lg: "120px" }}>
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          <Text textAlign="center">Delegate</Text>
          <ModalCloseButton right={4} top={4} />
        </ModalHeader>
        <ModalBody pb={4}>
          {!delegateeAccount ? (
            <GlobalDelegateOptions
              custodyBalance={custodyBalance}
              delegateeIneligible={delegateeIneligible}
              handleSubmit={handleSubmit}
              isDelegatingTo={isDelegatingTo}
              onChange={() => {
                if (delegateeIneligible) {
                  setDelegateeIneligible(false)
                }
              }}
              onDelegateMyself={() => handleDelegate(publicKey)}
              onDelegateSomeoneElse={() => setIsDelegatingTo(true)}
            />
          ) : (
            <DelegateTo
              custodyBalance={custodyBalance}
              delegateeAccount={delegateeAccount}
              onDelegate={async () =>
                handleDelegate(new PublicKey(delegateeAccount.address))
              }
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

type GlobalDelegateOptionsProps = {
  isDelegatingTo: boolean
  onDelegateMyself: () => void
  onDelegateSomeoneElse: () => void
  onChange: () => void
  handleSubmit: (values: {
    delegateeSolanaAddress: string
  }) => Promise<string | undefined>
  delegateeIneligible: boolean
  custodyBalance: string | null
}

const GlobalDelegateOptions = ({
  isDelegatingTo,
  onDelegateMyself,
  onDelegateSomeoneElse,
  onChange,
  handleSubmit,
  delegateeIneligible,
  custodyBalance,
}: GlobalDelegateOptionsProps) => {
  const validationSchema = Yup.object({
    delegateeSolanaAddress: Yup.string()
      .test({
        message: FAILED_VALIDATION_MESSAGE,
        test: (value) => (value ? isSolanaAddress(value) : false),
      })
      .required("Required"),
  })

  return (
    <Stack py={2} spacing={4}>
      {!isDelegatingTo ? (
        <>
          <Button variant="secondary" width="full" onClick={onDelegateMyself}>
            <Text fontWeight="500" textStyle="md">
              Myself
            </Text>
          </Button>
          <Button
            variant="secondary"
            width="full"
            onClick={onDelegateSomeoneElse}
          >
            <Text fontWeight="500" textStyle="md">
              Someone else
            </Text>
          </Button>
        </>
      ) : (
        <Formik
          initialValues={{ delegateeSolanaAddress: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            values,
            isValid,
            handleChange,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <Stack>
                  <FormInput
                    shouldShowValidationIcon
                    errors={errors}
                    inputProps={{
                      size: "sm",
                      h: "10",
                      focusBorderColor: "",
                    }}
                    label="Address"
                    name="delegateeSolanaAddress"
                    placeholder="Enter a Solana address"
                    touched={touched}
                    values={values}
                    onChange={(e: ChangeEvent<any>) => {
                      onChange()
                      handleChange(e)
                    }}
                  />
                  {delegateeIneligible ? (
                    <Text color="red.500" fontSize="sm">
                      This address is not eligible to receive delegations
                    </Text>
                  ) : null}
                </Stack>
                <Button
                  isDisabled={
                    !isValid ||
                    !values.delegateeSolanaAddress ||
                    delegateeIneligible
                  }
                  type="submit"
                  variant="primary"
                >
                  <Text fontWeight="500" textStyle="md">
                    Delegate votes {custodyBalance ?? "0"} W
                  </Text>
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  )
}

type DelegateToProps = {
  delegateeAccount: Account
  custodyBalance: string
  onDelegate: () => void
}

const DelegateTo = ({
  delegateeAccount,
  custodyBalance,
  onDelegate,
}: DelegateToProps) => {
  const displayName = getDisplayName(delegateeAccount)

  return (
    <Stack spacing={6}>
      <HStack>
        <UserAvatar
          address={delegateeAccount?.address}
          shouldShowBadge={false}
          src={delegateeAccount?.picture}
        />
        <Text textStyle="md">
          {isSolanaAddress(displayName)
            ? shortSolAddress(displayName)
            : displayName}
        </Text>
      </HStack>
      <Button type="submit" variant="primary" onClick={onDelegate}>
        <Text fontWeight="500" textStyle="md">
          Delegate votes {custodyBalance ?? "0"} W
        </Text>
      </Button>
    </Stack>
  )
}

export default StakingDelegateModal
